
















import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import GameHud from '@/components/GameHud.vue'
import GameMap from '@/components/GameMap.vue'
import GameVideo from '@/components/GameVideo.vue'
import EmailViewer from '@/components/EmailViewer.vue'
import PasswordEntry from '@/components/PasswordEntry.vue'
import Fanfair from '@/components/Fanfair.vue'
import Flair from '@/components/Flair.vue'
import Curtain from '@/components/Curtain.vue'

export default Vue.extend({
  name: 'Boardwalk',
  components: {
    GameHud,
    GameMap,
    GameVideo,
    EmailViewer,
    PasswordEntry,
    Fanfair,
    Flair,
    Curtain
  },
  props: {
  },
  data () {
    return {
      curtainActive: true,
      curtainHeading: 'Boardwalk',
      exerciseWindow: null,
      showFanfair: false,
      document: {
        href: '/docs/Cipher Sheet.pdf',
        label: 'Open Cipher Sheet'
      }
    }
  },
  computed: {
    ...mapGetters([
      'team',
    ]),
    stageVideoCompleted () {
      return (this.team.ready && this.team.completedBoardwalkVideo)
    },
    stageCompleted () {
      return (this.team.ready && this.team.completedBoardwalk)
    },
  },
  methods: {
    ...mapActions([
      'timerStart',
      'timerStop',
      'completeStageVideo',
      'completeStage'
    ]),
    handleHudClick (action: string) {
      if (typeof this[action] === 'function') {
        this[action]()
      }
    },
    showMap () {
      this.$refs.gameMap.show()
    },
    openVideo () {
      this.$refs.video.show()
    },
    goto (loc: string, heading: string, delay: number) {
      console.log('Maproom.goto')
      this.curtainHeading = heading
      this.curtainActive = true
      window.setTimeout(()=>{
        this.$router.push(loc)
      }, delay)
      this.timerStop()
    },
    onCurtainClick () {
      this.curtainActive = false
    },
    startExercise () {
      // this.exerciseWindow = window.open(
      //   '/outlook/index.php?d=2hover',
      //   '2hover',
      //   'popup,top=0,left=0,height='+window.screen.availHeight+',width='+window.screen.availWidth
      // );
      // this.$refs.passwordEntry.show()
      this.$refs.emailViewer.show()
      window.addEventListener('message', this.listenForCompletionMessage);
    },
    onExerciseHide () {
      window.removeEventListener('message', this.listenForCompletionMessage)
    },
    showPasswordTablet () {
      this.$refs.passwordEntry.show()
    },
    listenForCompletionMessage (message) {
      const data = message.data;
      console.log('received message from EmailViewer', data);
      if (data === 'complete') {
        this.completeExercise();
      }
    },
    completeExercise () {
      // this.exerciseWindow.close()
      this.completeStage('Boardwalk')
      this.timerStop()
    },
    completeVideo () {
      this.completeStageVideo('Boardwalk')
      if (this.team.seenFindLaptopHint<2) this.$refs.flair.show()
      this.timerStart('Boardwalk')
    }
  },
  mounted () {
    console.log('Boardwalk.mounted', this.stageVideoCompleted)
    this.$store.dispatch('updateMyTeam', {lastLocation: 'Boardwalk'})
    window.setTimeout(()=>{
      this.curtainActive = false
      if (!this.stageVideoCompleted) this.$refs.video.show()
      else if (this.stageCompleted) this.$refs.fanfair.show()
      else {
        if (this.team.seenFindLaptopHint<2) this.$refs.flair.show()
        this.timerStart('Boardwalk')
      }
    }, 500)
  },
  watch: {
    stageCompleted () {
      if (this.stageCompleted) {
        this.$refs.emailViewer.hide()
        this.$refs.passwordEntry.hide()
        this.$refs.fanfair.show()
        window.setTimeout(this.showMap, 5000)
      } else {
        this.$refs.fanfair.hide()
      }
    }
  }
});
